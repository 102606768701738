.myaccount-content-row{
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 100%;
    padding: 5px;
    /*border: 1px solid red;*/
    margin: 8px 0;
    background-color: white;
}
.subscription-ind{
    display: flex;
    flex-direction:column;
    align-items: center;
    text-align: center;
    width: 1417px;
}
.myaccount-dashedborder{
    display: flex;
    flex-direction:column;
    border: 2px dashed #9f0016;
    border-radius: 10px;
    padding: 8px;
}
.myaccount-setting-text{
    text-align: center;
    font-weight: 800;
    font-size: 20px;
}
.subtitles{
    font-weight: 800;
}
.subscription-bs-content-left{
    display: flex;
    justify-content: left;
    width: 705px;
}
.subscription-ind-content-right{
    display: flex;
    flex-direction:column;
    justify-content: left;
    width: 705px;
}
.subscr-table-i-2{
    border-collapse:collapse;
    border-spacing:0;
    font-weight: 700;
    margin-top: 10px;
    width: 700px;
}
.td-sb-i{
    text-align:left;
    padding: 5px;
    font-size:14px;
    width: 25%;
}
.subscription-bs-title{
    font-weight: 700;
    padding: 30px;
}
.subscr-input{
    width: 160px; 
}
.subscr-input-country{
    width: 168px; 
}
.uitable{
    border-collapse:collapse;
    border-spacing:0;
    font-weight: 700;
    margin-top: 10px;
    width: 1400px;

}

.uitable td {
    font-size:14px;
    overflow:hidden;
    text-align: center;
}
.uitable th{
    font-size:10px;
    overflow:hidden;
    padding:3px 3px;
    word-break:normal;
    text-align: center;

}
.th-border-bottom{
    border: 1px solid black;
    border-bottom: none;
}
.th-ui-rm{
    text-align:center;
    width: 90px;
}
.th-ui-email{
    text-align:center;
}
.th-border-bottom{
    border: 1px solid black;
    border-bottom: none; 
}
.th-ui-reg{
    text-align:center;
    width: 55px;
}
.th-ui-rs{
    text-align:center;
    width: 90px;  
}
.td-ui{
    text-align:center;
    vertical-align:top;
    padding-bottom: 5px;
}
.ui-button{
    background-color: white;
    height: 17px;
    font-size: 10px;
}
.td-ui-border{
    border-left: 1px solid black;
    border-right: 1px solid black;
}
.setuppassspan{
    color: #9f0016;
    font-weight: 700;
    font-size: 10px;
}
.lasttd-ui-border{
    border: 1px solid black;
    border-top: 1px none black;
}
.inputCard{
    width: 30px;
}
.create-acc-button{
    margin: 15px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
input[type='checkbox']{
  margin: 0;
  padding: 0;
}
.inputnumber1{
    width: 55px;
}
input{
    accent-color: #fecf73;
}

/****************/

.subscription-ind-content{
    display: flex;
    justify-content: center;
    width: 1410px;

}
.subscription-ind-content-left{
    display: flex;
    justify-content: left;
    width: 705px;
    border-right: 2px dashed #9f0016;

}
.subscription-bs-content-left{
    display: flex;
    justify-content: left;
    width: 705px;


}

.subscription-ind-content-right{
    display: flex;
    flex-direction:column;
    justify-content: left;
    width: 705px;
}
.subscr-table-i-1{
    border-collapse:collapse;
    border-spacing:0;
    font-weight: 500;
    margin-top: 10px;
    width: 700px;


}
.subscr-table-i-1 th{
    font-size:10px;
    overflow:hidden;
    padding:3px 3px;
    word-break:normal;
    text-align: center;
}
/* from old frame1.css*/
#slidernob{
    position:  relative;
    top: 20px;
    left: -16px;
    cursor: pointer;
}
.sliderbar{
    width: 1px;
    height: 385px;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    margin-top: 15px;
    margin-left: 35px;
}
.sliderdiv{
    width: 70px;
    height: 425px;
    /*border: 4px solid yellow;*/
}
.frame1maincontainer{
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 1480px;
    border: 4px solid green;
}
.frame1downcontainer{
  display: flex;
  width: 1550px;
  height: 425px;
  /*border: 4px solid gray; */
  justify-content: center;
}
.frame1upcontainer{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("./Frame1.png");
    width: 1671px;
    height: 620px;
    display: flex;
    /*border: 4px solid red;*/
  }
  .frame1header{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    width: 1550px;
    height: 105px;
}

.frame1title{
    font-weight: 800;
    font-size: 40px;
    text-decoration: underline;
    margin: 10px;   
}