.myreports-content-item{
    display: flex;
    width: 100%; 
    border: 1px solid black;
    margin-bottom: 4px;
    margin-left: 4px;
}
.myreports-content-item-left{
    position: relative;
    top: 0;
    left: 0;

    
    width: 110px;
    /*border: 1px solid red;*/
}
.myreports-content-item-image{
    position: relative;
    
    max-width: 110px;
    top: 0;
    left: 0;
    padding: 0px;
}
.myreports-content-item-number{
    position: absolute;
    top: -2px;
    left: 65px;
    width: 60px;
}
.myreports-content-item-right{
  display: flex;
  flex-direction:column;
  width: 1290px;
  /*border: 1px solid green;*/
}
.myreports-content-item-header{
    display: flex;
    justify-content: space-between;
    width: 1290px;
    /*border: 1px solid blue; */
}
.myreports-content-item-header-text{
    width: 425px;
    padding: 10px;
    font-weight: 700;
    font-size: 13px;
    /*border: 1px solid red;*/
}
.myreportcontentmenu{
    display: flex;
    justify-content: space-between;
}
.myreportmenu-item{
    width: 55px;
    max-height: 27px;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    border: 1px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    
}
.myreports-content-item-body{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1290px;
    /*border: 1px solid yellow;*/
}
.myreports-content-bar{
   margin-top: 10px;
   width: 1200px;
   height: 22px;
   background-color: #cccccc;
   border: 1px solid #6d6d6d;
   text-align: center;
}
/* Table*/
.myreportstable{
   border-collapse:collapse;
   border-spacing:0;
   font-weight: 500;
}
.tg-0lax{
    text-align:left;
    vertical-align:top;
}
.td1{
    width: 120px;
}
.td2{
    width: 610px;
}
.td3{
    width: 120px;
}
.td4{
    width: 120px;
}
.td5{
    width: 120px;
}
.td6{
    width: 210px;
}
.reportscontainer0{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bg/SimpleFrameLandscape.svg");
    width: 1671px;
    height: 620px;
    display: flex;
  
    /*border: 4px solid red;  */
  }
  
  .reportscontainer1{
      display: flex;
      justify-content: space-between;
      width: 1550px;
      height: 105px;
       /*border: 4px solid blue; */
  }
  .reportscontainer2{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 1450px;

    /*border: 4px solid gray;*/
  }
  .reportscontainer3{
      display: flex;
      justify-content:space-between;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 1480px;
      /*border: 4px solid green; */
      padding: 2px;
  }
  .reportsscbardiv{
    width: 70px;
    height: 425px;
    /*border: 4px solid yellow;*/
}
.reportsscbar{
    width: 1px;
    height: 385px;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    margin-top: 15px;
    margin-left: 35px;
}
#reportsslider{
    position:  relative;
    top: 20px;
    left: -16px;
    cursor: pointer;
}
.reportsmedia1{
    display: flex;
    flex-direction:column;
    border: 2px solid black;
    width: 230px;
    height: 230px;
    margin-top: 30px;
}
.reportsmedia2{
    display: flex;
    justify-content: space-between;
    height: 70px;

   /* border: 1px solid red;*/
}
.reportsmedia2-box{

    width: 55px;
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    border: 2px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.reportsmedia3{
    display: flex;
    flex-direction:column;
    justify-content: center;
    height: 100%;
    /*border: 1px solid green; */
}
.thumbnailinreports{
    height: 60px;
    width: 60px;
}
.reportsmedia3-box{
    margin: 5px;
    text-align: center;
    font-weight: 800;
    font-size: 15px;
}
.myreportsspan{
    font-weight: 800;
    font-size: 40px;
    text-decoration: underline;
    margin: 10px;
}

.emptyreportsdiv{
    width: 55px;
    max-height: 27px;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    border: 2px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}


@media only screen and (max-width: 1700px) {

    .myreports-content-item{
        width: 100%;
        margin-bottom: 2px;
        margin-left: 2px;
        /*border: 1px solid green; */ 
    }
    .myreports-content-item-left{
        width: 110px;
    }
    .myreports-content-item-image{
        max-width: 79px;
    }
    .myreports-content-item-number{
        position: absolute;
        top: -2px;
        left: 42px;
        width: 40px;
    }
    .myreports-content-item-right{
      width: 1290px;
    }
    .myreports-content-item-header{
        display: flex;
        justify-content: space-between;
        width: 921px;
        /*border: 1px solid blue;*/
    }
    .myreports-content-item-header-text{
        width: 304px;
        padding: 7px;
        font-weight: 700;
        font-size: 9px;
        /*border: 1px solid red;*/
    }
    .myreportmenu-item{
        width: 39px;
        max-height: 19px;
        font-size: 11px;
        font-weight: 800;
        margin: 5px;
        padding-top: 11px;
        padding-bottom: 11px;

    }
    .myreports-content-item-body{
        width: 921px;
    }
    .myreports-content-bar{
       margin-top: 7px;
       width: 857px;
       height: 8px;
    }
    /* Table*/
    .myreportstable{
       font-weight: 500;
       font-size: 11px;

    }
    .td1{
        width: 86px;
    }
    .td2{
        width: 436px;
    }
    .td3{
        width: 86px;
    }
    .td4{
        width: 86px;
    }
    .td5{
        width: 86px;
    }
    .td6{
        width: 150px;
    }
    .reportscontainer0{
        background-image: url("../../assets/img/bg/SimpleFrameLandscape1700.svg");
        width: 1194px;
        height: 443px;
        /*border: 1px solid blue;*/
      }
      
      .reportscontainer1{

          width: 1107px;
          height: 75px;
      }
      .reportscontainer2{
        width: 1035px;
      }
      .reportscontainer3{
          width: 1057px;
      }
      .reportsscbardiv{
        width: 50px;
        height: 304px;
    }
    .reportsscbar{
        height: 275px;
        margin-top: 10px;
        margin-left: 14px;
    }
    #reportsslider{
        position:  relative;
        top: 20px;
        left: -16px;
        cursor: pointer;
    }
    .reportsmedia1{
        display: flex;
        flex-direction:column;
        border: 2px solid black;
        width: 230px;
        height: 230px;
        margin-top: 30px;
    }
    .reportsmedia2-box{
        width: 39px;
        font-size: 11px;
        font-weight: 800;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    
    .thumbnailinreports{
        height: 40px;
        width: 40px;
    }
    .reportsmedia3-box{
        margin: 5px;
        text-align: center;
        font-weight: 800;
        font-size: 15px;
    }
    .myreportsspan{
        font-weight: 800;
        font-size: 30px;
        text-decoration: underline;
        margin: 7px;
    }
    
    .emptyreportsdiv{
        width: 39px;
        max-height: 18px;
        text-align: center;
        font-size: 15px;
        font-weight: 800;
        border: 2px solid black;
        margin: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }      


    }