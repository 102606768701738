.pickaccountswitch{
    display: flex;
}
.pickaccountswitchleft{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(./pickaccountlight.svg);
    width: 320px;
    height: 50px;
    color: white;
    font-size: 25px;
    cursor: default;    
}
.pickaccountswitchright{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(./pickaccountdark.svg);
    width: 320px;
    height: 50px;
    color: #a5a5a4;
    font-size: 25px;
    margin-right: 60px;
    cursor:pointer;
}