.container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bg/SimpleFrameLandscape1300_450.svg");
    width: 1300px;
    height: 450px;
}
.divtitle{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 50px;
    padding-top: 35px;   
    /*border: 1px solid green; */
}
.spantitle{
    font-weight: 800;
    font-size: 40px;
    text-decoration: underline;
    margin: 0px; 
    font-family: 'Josefin Sans', sans-serif;   
}
.divtext{
    display: flex;
    width: 1100px;
    height: 80px;
    font-family: 'Josefin Sans', sans-serif; 
    /*border: 1px solid red; */
}
.divtext p{
    font-size: 35px;
    padding: 20px;
    margin: 0px 0;   
}
.buttons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1100px;
    height: 220px;  
    /*border: 1px solid gray;  */
}