.newimage{
    margin-right: 16px;
    z-index: 1;
}
.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 131px;
}
.dropdown-content {
    display: none;
    position: absolute;
    left: -260px;
    top: 93px;
    background-color: #000;
    min-width: 400px;
    height: 505px;
    z-index: 5;
    margin-top: 40px;
  }


  .dropdown-content a {
    color: #ffd786;
    font-size: 32px;
    font-weight:600;
    padding: 2px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-end;
    margin-top: 20px;
  }

  .divasa {
    color: #ffd786;
    font-size: 32px;
    font-weight:600;
    padding: 2px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-end;
    margin-top: 20px;
    cursor: pointer;
  }


  .aligned {
    display: flex;
    align-items: center;
}
.gmenutitle{
    display: flex;
    flex-direction:column;
    align-items: baseline;
    justify-content: center;
}


  .gmenutitle_myaccount{
    margin-left: 36px
  }
  .gmenutitle_myaccount span{
    text-align: center;
    padding-left: 10px;
  }
  .gmenutitle_myportfolio{
    margin-left: 15px
  }
  .gmenutitle_myportfolio span{
    padding-left: 10px;
  }

  .gmenutitle_logout{
    margin-left: 25px
  }
  .gmenutitle_logout span{
    text-align: center;    
    padding-left: 40px;
  }


  .gmenutitle_myreports{
    margin-left: 45px
  }
  .gmenutitle_myreports span{
    padding-left: 15px;
  }
  .gmenutitle_about{
    margin-left: 5px
  }

  .dropdown:hover .dropdown-content {display: block;}
