header {
    position: sticky;
    display: flex;
    justify-content: space-between;
    height: 135px;
    top: 0;
    background-color: #000;
       z-index: 100;
}
.logo-dropdown {
    position: relative;
    display: inline-block;
  }
  .logoimage{
    margin-left: 40px;
    margin-top: 17px;
    cursor: pointer;
}
.dropdown-content-logo {
    display: none;
    position: absolute;
    left: 40px;
    top: 74px;
    background-color: #000;
    height: 315px;
    margin-top: 60px;
    z-index: 100;
  }
  .dropdown-content-logo a {
    color: #ffd786;
    font-size: 32px;
    font-weight:600;
    padding: 2px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-end;
    margin-top: 20px;
  }
  .aligned {
    display: flex;
    align-items: center;
}
.gmenutitle{
    display: flex;
    flex-direction:column;
    align-items: baseline;
    justify-content: center;
}
.bar{
    border: 3px solid #ffd786;
    border-radius: 5px;
    width: 200px;
    height: 1px;
    margin-top: 7px;
    background-color: #ffd786;
  }
  .headermenu{
    display: none;
    justify-content: right;
    align-items: center;
    width: 330px;
    background-color: #000;
    /*border: 4px solid green;*/
    margin-right: 20px;
    z-index: 1;
}
.gmenutitle_aboutus{
    margin-left: 15px;
}
.gmenutitle_aboutus  span{
    padding-left: 33px;
}
.gmenutitle_terms{
    margin-left: 15px;
}
.gmenutitle_terms  span{
    text-align: center;
    padding-left: 25px;  
}
.gmenutitle_news{
    margin-left: 15px;
}
.gmenutitle_news span{
    padding-left: 61px;
}

.logo-dropdown:hover .dropdown-content-logo {display: block;}