.loginrow{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
   
}

#loginpassword, #loginemail{
   height: 40px; 
   font-size: 20px;
   text-align: center;
}