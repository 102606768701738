.rightarrow{
    margin-left: 0px;
    cursor: pointer;
}
.leftarrow{
   margin-right: 10px;
   cursor: pointer;   
}
.searchcontainer0{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bg/SimpleFrameLandscape.svg");
    width: 1671px;
    height: 620px;
    display: flex;
    /*border: 4px solid red;*/
  }
  .searchcontainer1{
      display: flex;
      justify-content: space-between;
      width: 1550px;
      height: 105px;
      /*border: 1px solid blue;*/
  }
  .searchcontainer2{

    width: 1470px;
    height: 410px;
    margin-bottom: 20px;
    /*border: 1px solid gray; */
  }

  .largemediaimage{
    height: 380px;
    padding: 10px; 
  }
  .smallmediaimage{
    max-height: 82px;
    max-width: 132px;
    padding: 3px; 
  }
  .smallmediadiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 140px;
    /*border: 1px solid violet; */
  }

.largemedialist{
  display: flex; 
  flex-wrap:nowrap;
  /*overflow: auto;*/
  /*overflow: hidden; */
}
.smallmedialist{
  height: 400px;
  display: flex; 
  flex-direction:column;
  flex-wrap:wrap;
}


  .searchcontainer3{
    width: 1550px;
    height: 120px;
    /*border: 4px solid yellow; */
  }
  .sortmenuimage{
    position: relative;
    top: 13px;
    left: 40px;
      z-index: 1;
  }
  .twoimagesdiv{
    position: relative;
    top: 0;
    left: 0
  }
.portfoliobgimage{
    position: relative;
    top: 17px;
    left: -37px;
    cursor: pointer;
  }
  .portfoliolargeimage{
    display: block;
    position: absolute;
    top: 16px;
    left: 90px;
  }
  .portfoliosmallimage{
    display: none;
    position: absolute;
    top: 16px;
    left: -38px;
  }
.scbarr{
    width: 1490px;
    height: 1px;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.2);
    margin-top: 40px;
    margin-left: 31px;
}
#portfolioslider{
    position:  relative;
    top: -15px;
    left: 60px;
    cursor: pointer;
}

@media only screen and (max-width: 1700px) {

.searchcontainer0{
    background-image: url("../../assets/img/bg/SimpleFrameLandscape1700.svg");
    width: 1194px;
    height: 443px;


  }
  .searchcontainer1{
      width: 1107px;
      height: 75px;
      /*border: 1px solid blue;*/
  }
  .searchcontainer2{

    width: 1050px;
    height: 293px;
    margin-bottom: 14px;
    /*border: 1px solid gray; */
  }

  .largemediaimage{
    height: 271px;
    padding: 7px; 
  }
  .smallmediaimage{
    max-height: 59px;
    max-width: 94px;
    padding: 3px; 
  }
  .smallmediadiv{
    height: 64px;
    width: 100px;
  }


.smallmedialist{
  height: 285px;
}


  .searchcontainer3{
    width: 1107px;
    height: 86px;

  }
  .sortmenuimage{
    top: 20px;
    left: 29px;
    width: 60px;
    height: 40px;
  }
  .twoimagesdiv{
    top: 0;
    left: 0
  }
.portfoliobgimage{
    top: 12px;
    left: -37px;
    width: 204px;
    height: 55px;
  }
  .portfoliolargeimage{
    top: 11px;
    left: 51px;
    width: 117px;
    height: 58px;
  }
  .portfoliosmallimage{
    top: 11px;
    left: -38px;
    width: 117px;
    height: 58px;
  }
.scbarr{
    width: 1064px;
    margin-top: 40px;
    margin-left: 31px;
}
#portfolioslider{
    position:  relative;
    top: -15px;
    left: 60px;
    cursor: pointer;
}

}