.reglink{
    text-decoration: none;
}
.brownbuttonreg{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/img/buttons/BrownButtonReg.svg");
    height: 70px;
    width: 250px;
    color: white;
    font-weight: 600;
    font-size: 28px;
    font-family: 'Josefin Sans', sans-serif;
    cursor: pointer;
}
.brownbuttonreg2{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/img/buttons/BrownButtonReg2.svg");
    height: 50px;
    width: 250px;
    color: white;
    font-weight: 600;
    font-size: 25px;
    font-family: 'Josefin Sans', sans-serif;
    cursor: default;
}

.brownbuttonreg2pointer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/img/buttons/BrownButtonReg2.svg");
    height: 50px;
    width: 250px;
    color: white;
    font-weight: 600;
    font-size: 25px;
    font-family: 'Josefin Sans', sans-serif;
    cursor: pointer;
}


.brownbuttonsmall{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/img/buttons/BrownButtonSmall.svg");
    height: 70px;
    width: 140px;
    color: white;
    font-weight: 600;
    font-size: 24px;
    font-family: 'Josefin Sans', sans-serif;
    cursor: pointer;
}
.brownbuttonfat{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/buttons/BrownButtonFat.svg");
    text-align: center;
    height: 120px;
    width: 440px;
    color: white;
    font-weight: 600;
    font-size: 28px;
    font-family: 'Josefin Sans', sans-serif;
    cursor: pointer;
}
.titlepadding{
    padding: 10px;;
}