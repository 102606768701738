.shareemailsave{
    display: flex;
    justify-content: space-between;
}
.shareemailsave-item{
    width: 55px;
    max-height: 27px;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    border: 1px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}