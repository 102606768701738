.passresetrow{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
   
}

#emailpassreset, #phonepassreset,
#resetpassword, #confirmresetpassword{
   height: 40px; 
   font-size: 20px;
   text-align: center;
}