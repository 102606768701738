footer {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    height: 120px;
}
.emptydiv1{
    width: 79px;

}
/*display: flex;*/
.footericons{
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 10px;
}
.twoimagesdiv{
  position: relative;
  top: 0;
  left: 0
}
.underimage {
  position: relative;
  top: 0;
  left: 0;
}
.frequencyimage {
  position: absolute;
  top: -20px;
  left: 7px;
}
.portfolioimage {
  position: absolute;
  top: -26px;
  left: 5px;
}
.portfolioimagetext{
    position: absolute;
    top: 23px;
    left: 115px;
    color: white;
    font-size: 27px;
}
.frequencyimagetext{
    position: absolute;
    top: 23px;
    left: 115px;
    color: white;
    font-size: 27px;
}
.alertimage {
  position: absolute;
  top: -7px;
  left: 25px;
}
.rednumber23image{
  position: absolute;
  top: -5px;
  left: 50px;
}
.alertemailimage {
  position: absolute;
  top: 8px;
  left: -3px;
}
.alertsmsimage {
  position: absolute;
  top: -6px;
  left: 111px;
}
.alertwebimage {
  position: absolute;
  top: -6px;
  left: 195px;
}
.marginright10{
    margin-right: 10px;
}
.footertrash{
  display: none;
  cursor: pointer;
}