.msg_tmpl1_container0{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bg/SimpleFrameLandscape.svg");
    width: 1671px;
    height: 620px;
}
.msg_tmpl1_header_span{
    font-weight: 800;
    font-size: 45px;
    text-decoration: underline;
    margin: 10px;
}
.termslink{
    text-decoration: underline;
    cursor: pointer;
}
.msg_tmpl1_footer_span{
    font-weight: 500;
    font-size: 18px;
    padding: 30px;
}
.msg_tmpl1_container1{
    display: flex;
    justify-content: space-between;
    width: 1550px;
    height: 60px;
}
.msg_tmpl1_container2{
    display: flex;
  width: 1550px;
  height: 380px;
  /*border: 4px solid gray;*/
}
.msg_tmpl1_container2 p {
    font-size: 45px;
    padding: 20px;
    margin: 10px 0;
}
.msg_tmpl1_container4{
  display: flex;
  justify-content: center;
  width: 1550px;
  height: 30px;
  
  /*border: 2px solid black;*/
}

.msg_tmpl1_container5{
    display: flex;
    justify-content: right;
    width: 1550px;
    height: 35px;
    /*border: 2px solid black;*/
  }

.msg_tmpl1_container0 img {
    cursor: pointer;
    width: 40px;
    height: 40px;

}

input{
    accent-color: #fecf73;
}

@media only screen and (max-width: 1700px) {
    .msg_tmpl1_container0{
        background-image: url("../../assets/img/bg/SimpleFrameLandscape1700.svg");
        width: 1194px;
        height: 443px;
    }

    .msg_tmpl1_header_span{
        font-size: 32px;
        margin: 7px;
    }
    .msg_tmpl1_footer_span{
        font-size: 21px;
        padding: 20px;
    }
    .msg_tmpl1_container1{
        width: 1107px;
        height: 43px;
    }
    .msg_tmpl1_container2{
      width: 1107px;
      height: 271px;
    }
    .msg_tmpl1_container2 p {
        font-size: 32px;
        padding: 14px;
        margin: 7px 0;
    }
    .msg_tmpl1_container4{
      width: 1107px;
      height: 30px;
    }
    .msg_tmpl1_container5{
        display: flex;
        justify-content: right;
        width: 1107px;
        height: 43px;
        /*border: 2px solid black;*/
      }

    .msg_tmpl1_container0 img {
        width: 26px;
        height: 26px;
    
    }
}