.myaccount-content-row{
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 100%;
  
    padding: 5px;
    /*border: 1px solid red;*/
    margin: 8px 0;
  }
  .myaccount-settings{
      width: 415px;
  }
  .myaccount-dashedborder{
    display: flex;
    flex-direction:column;
    border: 2px dashed #9f0016;
    border-radius: 10px;
    padding: 8px;
}
.myaccount-setting-text{
    text-align: center;
    font-weight: 800;
    font-size: 20px;
}
#update-nbr{
    
}
.myaccount-twoimagesdiv{
    position: relative;
    top: 0;
    left: 0
  }
.myaccount-marginbottom{
    margin-bottom: 20px;
}
.underimage {
    position: relative;
    top: 0;
    left: 0;
  }
  .frequencyimage {
    position: absolute;
    top: -20px;
    left: 7px;
  }
  .portfolioimage {
    position: absolute;
    top: -26px;
    left: 5px;
  }
  .portfolioimagetext{
    position: absolute;
    top: 23px;
    left: 115px;
    color: white;
    font-size: 27px;
}
.frequencyimagetext{
    position: absolute;
    top: 23px;
    left: 115px;
    color: white;
    font-size: 27px;
}
.myaccount-setting-update{
    display: none;
    flex-direction:column;
    /*justify-content: center;*/
    align-items: center;
    width: 135px;
    height: 240px;
    padding: 10px;
    background-color: #6e5c33;
    border-radius: 10px;
    position: absolute;
    color: white;
  }
  .myaccount-setting-update-nbr{
      display: none;
      top: 0px;
      left: 275px;
  }
  .myaccount-setting-update-fr{
      display: none;
      top: -95px;
      left: 275px;
  }
  .myaccount-setting-update-nt{
      display: none;
      top: -189px;
      left: 275px;
  }
#update-fr{
    
}

.alertsmsimage {
    position: absolute;
    top: -6px;
    left: 111px;
  }
#fr-update-form{

}
#submit-update-fr{
    
}
#update-nt{
    
}
.setting-update-form{
    
}
.alertemailimage {
    position: absolute;
    top: 8px;
    left: -3px;
  }


#nt-update-form{
    
}
.myaccount-myinfo{
    text-align: center;
    width: 365px;
    height: 280px;
}
.myaccount-myinfo p{
    margin: 7px 0;
}
.myaccount-billinginfo{
    width: 530px;
    height: 280px;
}
.myaccount-billinginfo-content{
    display: flex;
    width: 559px;
    height: 260px;
}
.myaccount-billinginfo-left{
    width: 278px;
    height: 260px;
}
.myaccount-billinginfo-right{
    width: 278px;
    height: 260px;
}

.myaccount-billinghistory{
    width: 450px;
    min-height: 280px;
}

.myaccount-accounthistory{
    width: 450px;
    min-height: 280px;
}

.mybhtable{
    border-collapse:collapse;
    border-spacing:0;
    font-weight: 500;
    margin-top: 10px;
}

.mybhtable td {
    font-size:14px;
    overflow:hidden;
    text-align: center;
}
.mybhstable th{
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:5px 5px;
    word-break:normal;
    text-align: center;
}
.tg-bh{
    text-align:center;
    vertical-align:top;
    padding-bottom: 5px;
}
.td1-bh{
    width: 130px;
}
.td2-bh{
    width: 130px;
}
.td3-bh{
    width: 80px;
}
.td4-bh{
    width: 120px;
}
.myahtable{
    border-collapse:collapse;
    border-spacing:0;
    font-weight: 500;
    margin-top: 10px;
}

.myahtable td {
    font-size:14px;
    overflow:hidden;
    text-align: center;
}
.myahstable th{
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:5px 5px;
    word-break:normal;
    text-align: center;
}
.tg-ah{
    text-align:center;
    vertical-align:top;
    padding-bottom: 5px;
}
.td1-ah{
    width: 130px;
}
.td2-ah{
    width: 130px;
    text-align: left;
}
.td3-ah{
    width: 80px;
}
.td4-ah{
    width: 120px;
}
.span-ah{
    color: red;
}
.myaccount-exurl{
    width: 410px;
    min-height: 280px;
}


.alertwebimage {
    position: absolute;
    top: -6px;
    left: 195px;
  }

/* Settings  menu  on hover */
#update-nbr:hover .myaccount-setting-update-nbr{display: flex;}
#update-fr:hover .myaccount-setting-update {display: flex;}
#update-nt:hover .myaccount-setting-update-nt {display: flex;}


.trashcontainer0{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("./PortfolioFrame1.png");
    width: 1671px;
    height: 620px;
    display: flex;
  
    /*border: 4px solid red;*/
  }
  
  .trashcontainer1{
      display: flex;
      justify-content: space-between;
      width: 1550px;
      height: 105px;
       /*border: 4px solid blue; */
  }
  .trashcontainer2{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 1400px;

    /*border: 4px solid gray;*/
  }
  .trashcontainer3{
      display: flex;
      justify-content:space-between;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 1480px;
      /*border: 4px solid green; */
      padding: 2px;
  }
  .trashscbardiv{
    width: 70px;
    height: 425px;
    /*border: 4px solid yellow;*/
}
.trashscbar{
    width: 1px;
    height: 385px;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    margin-top: 15px;
    margin-left: 35px;
}
#trashslider{
    position:  relative;
    top: 20px;
    left: -16px;
    cursor: pointer;
}
.trashmedia1{
    display: flex;
    flex-direction:column;
    border: 2px solid black;
    width: 230px;
    height: 230px;
    margin-top: 30px;
}
.trashmedia2{
    display: flex;
    justify-content: space-between;
    height: 70px;

   /* border: 1px solid red;*/
}
.trashmedia2-box{

    width: 55px;
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    border: 2px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.trashmedia3{
    display: flex;
    flex-direction:column;
    justify-content: center;
    height: 100%;
    /*border: 1px solid green; */
}
.thumbnailintrash{
    height: 60px;
    width: 60px;
}
.trashmedia3-box{
    margin: 5px;
    text-align: center;
    font-weight: 800;
    font-size: 15px;
}
.mytrashspan{
    font-weight: 800;
    font-size: 40px;
    text-decoration: underline;
    margin: 10px;
}

.emptytrashdiv{
    width: 55px;
    max-height: 27px;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    border: 2px solid black;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}
