.bg-main {
    background:  url("../assets/img/background.png") no-repeat center center;
    background-color:#693b4c;
    height: 825px;
    height: calc(100vh - 255px);

}
.flexbox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: calc(100vh - 255px);
    /*border: 4px solid green; */

}
.reglink{
    text-decoration: none;
}

@media only screen and (max-width: 1700px) {
    
}