.frame1upcontainer{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bg/SimpleFrameLandscape.svg");
    width: 1671px;
    height: 620px;
    display: flex;
    /*border: 4px solid red; */
  }
  .frame1header{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    width: 1550px;
    height: 105px;
    /*border: 4px solid blue;*/
}
.errormessage{
  font-weight: 800;
  font-size: 20px;
  color: red;
}
.frame1title{
    font-weight: 800;
    font-size: 40px;
    text-decoration: underline;
    margin: 10px;   
}
.frame1downcontainer{
    display: flex;
    flex-direction:column;
    width: 1550px;
    height: 425px;
    /*border: 4px solid gray; */
    justify-content: center;
  }

  .loginrow1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
   
}

#loginpassword1, #loginpassword2, #loginemail1{
   height: 40px; 
   font-size: 20px;
   text-align: center;
}

@media only screen and (max-width: 1700px) {

  .frame1upcontainer{

    background-image: url("../../assets/img/bg/SimpleFrameLandscape1700.svg");
    width: 1194px;
    height: 443px;
;
    /*border: 4px solid red; */
  }

  .frame1header{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    width: 1107px;
    height: 75px;
    /*border: 4px solid blue;*/
}

.frame1title{
    font-weight: 800;
    font-size: 32px;
    margin: 7px;   
}
.frame1downcontainer{

    width: 1107px;
    height: 304px;
    /*border: 4px solid gray; */
    justify-content: center;
  }

  .loginrow1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
   
}

#loginpassword1, #loginpassword2, #loginemail1{
   height: 32px; 
   font-size: 20px;
   text-align: center;
}

}